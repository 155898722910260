$background-color: #d7ccc8;
$font-family: "Roboto", sans-serif;

@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");

.heading {
  margin-top: 3%;
  display: flex;
  justify-content: center;
  // align-items: center;
  // text-align: center;
}

.container-delete {
  align-items: center;
}

.para-details {
  padding: 20px;
  display: flex;
  margin-top: 5%;
  font-size: larger;
  justify-content: center;
  align-content: center;
}

.delete-button {
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  outline: 0;
  border: 0;
  width: 14rem;
  padding: 15px;
  margin-top: 10%;
  background: #8b0000;
  border-radius: 10px;

  text-transform: uppercase;
}

.confirmationModal {
  display: flex;
}

.cancel-button {
  display: flex;
  justify-content: left;
  font-family: "Roboto", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 15px;
  outline: 0;
  border: 0;
  width: 14rem;
  /* Increased width */
  padding: 15px;
  margin-top: 100px;
  background: #5b99a6;
  border-radius: 10px;
  text-transform: uppercase;
}

@mixin message($fontcolor: #90a4ae) {
  // color: $fontcolor;
  text-align: center;
  margin-top: 3px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

@mixin input($bkgnd: #f5f5f5) {
  font-family: $font-family;
  width: 110%;
  /* Increased width */
  border: 0;
  // margin: 0 0 15px;
  padding: 15px;
  outline: 0;
  font-size: 15px;
  text-align: left;
  /* Align text to the left */
  box-sizing: border-box;
  background: $bkgnd;
}

.container-delete {
  // width: 70vw;
  margin: auto;
  // background-color: grey;
}

.para {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

// .form .delete-button {
//   background-color: #8b0000;
//   width: 300px;
//   display: flex;
//   justify-content: center;
//   align-content: center;
//   align-items: center;
// }

.error-message {
  color: red;
}

.form {
  position: relative;
  z-index: 1;
  max-width: 350px;
  margin: 0 auto 100px;
  padding: 45px;
  background: #ffffff;

  input {
    @include input;

    ::placeholder {
      color: #90a4ae;
      text-align: left;
      /* Align placeholder text to the left */
    }
  }

  .message {
    @include message;

    a {
      text-decoration: none;
      color: #00bfa5;
      margin-top: 5px;
    }
  }
}

.form .register-form {
  display: none;
}