$footeractive: #88aeb1;
$primaryheaderbg: #5b99a6;
$greenappcolor: rgb(91, 153, 166);
$primaryBlue: #d3d3d3;
$bodytext: black;
$bodywhite: #fff;
$white: #ffffff;
$bodygray: rgb(231, 229, 226);
$textColorInHeader: #000000;
$white: #ffffff;
// $textColorInHeader: #DED8C9;
$LoginContainerColor: #bbbbbb;
$TextFieldBackGround: red;
$CustomButtonColor: #937979;
$scroll-thumb-color: red;
$scroll-track-color: black;
$marketbids-card: #fafafa;
$pageheadingsize: 30px;

.custom-select-form{
    border: none; 
    border-bottom: 2px solid #c1c1c1; 
    padding: 0.5rem; 
    box-shadow: none; 
    outline: none;   
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 7px;

    &:focus {
        box-shadow: none; 
        border-bottom: 2px solid $primaryheaderbg; 
      }
  
  }

  $custom-select-form: ".custom-select-form";
  
 
  