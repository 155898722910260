@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../../assets/index";
.requests {
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column; /* Vertically align the cards */
  align-items: center;
  margin-bottom: 10%;
  h1{
    margin-bottom: 50px;
    margin-top: 20px;

  }
  .reqcard {
    border: 1px solid #ddd;

  margin-bottom: 16px;
  background-color: $marketbids-card;
  border-width: 1px;
    margin-top: 2px;
    margin-bottom: 10px;
    padding: 20px;
    border-radius: 20px;
    // width: 98vw;
    min-width: 80%;
    display: flex;
    .textdata {
      width: 70%;
      text-align: left;

      padding: 5px;
      @include breakpoint(mobileLarge) {
        width: 100%;
      }
    }
    .buttonsdata {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 5px;
      width: 30%;
      gap: 7px;

      @include breakpoint(mobile) {
        align-items: center;
        display: flex;
        flex-direction: column;
      }
      @include breakpoint(mobileLarge) {
        width: 100%;
      }
    }
    @include breakpoint(mobileLarge) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }
}
.PhoneInputInput{
  display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    background-clip: padding-box;
    border: var(--bs-border-width) solid var(--bs-border-color);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;

    &:focus {
      
      background-color: #E8F0FE;
      border-color: #86b7fe;
      outline: 0;
      box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25)
    }
}
.container-form{
  padding: 15px;
    display: flex;
    justify-content: center;
}
.AcceptBidForm{
  padding-top: 5px;
    width: 27vw;
}
.address{
  display: flex;
  flex-direction: row;
  gap: 14px;
}
.label{
  font-weight: bold;
}
.submitBtn{
  background-color: #5b99a6;
    color: white;
    border: none;
}