.customizecontainer {
  padding: 10px;
  h1 {
    text-align: center;
  }
  .formlabel1 {
    margin-top: 5px;
    font-size: 13px;
    font-weight: bold;
  }
  .datameasures {
    color: green;
    margin-left: 10px;
  }
  .dateborder {
    margin-top: 10px;
    padding: 5px;
    border: 1px solid gray;
    border-radius: 10px;
    .custom-datepicker {
      width: 100%;
      border: none;
    }
  }
}
