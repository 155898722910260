@import '~bootstrap/dist/css/bootstrap.min.css';
@import '../../assets/_variables';
@import "../../assets/index.scss";

@font-face {
    font-family: 'AutoWide';
    src: url('../../fonts/Audiowide-Regular.ttf') format('truetype');
}

.authentication-container {
    background-color: rgb(231, 229, 226);
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;


    .authentication-logo-section {
        display: flex;
        justify-content: center;
        margin-top: 12vh;
        // margin-bottom: 4vh;
        align-items: center;

        .authentication-logo-image {
            width: 15vw;
            height: 4vh;

            @include breakpoint(mobileLarge) {
                width: 37vw;
            }
        }

        .authentication-back-arrow {
            color: #5B99A6;
            cursor: pointer;
            position: absolute;
            left: calc(50% - 17vw);

            @include breakpoint(mobileLarge) {
                left: 18vw;
            }
        }
    }

    .authentication-box {
        background-color: $LoginContainerColor !important;
        display: flex;
        width: 35%;
        flex-direction: column;
        align-items: center;
        margin-top: 6rem;
        margin-bottom: 4rem;

        padding-bottom: 40px;
        padding-top: 2rem;
        box-shadow: 1px 10px 8px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;

        @include breakpoint(mobileLarge) {
            width: 80%;
        }
    }

    .authentication-title {
        font-family: sans-serif;
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 20px;
        color: #333333;
    }

    .privacyStatement {
        padding: 12px;
    }

    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .lowerimage {
        width: 5vw;
        height: 8vh;

        @include breakpoint(mobileLarge) {
            width: 17vw;
        }
    }

    p {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}


.verification-modal {
    .modal-header {
        background-color: #f5f5f5;
        border-bottom: 1px solid #ddd;
        padding: 1.5rem;

        .modal-title {
            font-size: 1.5rem;
            font-weight: 600;
            color: #333;
        }
    }

    .modal-body {
        padding: 2rem;

        .verification-form {
            .form-group {
                margin-bottom: 1.5rem;

                .form-label {
                    font-weight: 500;
                    color: #333;
                }

                .verification-input {
                    padding: 10px;
                    font-size: 1rem;
                    border-radius: 5px;
                    border: 1px solid #ddd;
                    width: 100%;
                }

                .error-message {
                    color: #d9534f;
                    font-size: 0.875rem;
                }
            }

            .custom-button {
                margin-top: 1.5rem;
                font-size: 1rem;
                font-weight: 500;
                padding: 12px 20px;
                border-radius: 5px;
            }
        }
    }
}


.otp-modal {
    .modal-header {
        background-color: #f5f5f5;
        padding: 1.5rem;

        .modal-title {
            font-size: 1.5rem;
            font-weight: 600;
            color: #333;
        }
    }

    .modal-body {
        padding: 2rem;

        .otp-instructions {
            text-align: center;
            font-size: 1rem;
            margin-bottom: 1.5rem;
            color: #333;
        }

        .otp-form {
            display: flex;
            flex-direction: column;
            align-items: center;

            .otp-inputs {
                display: flex;
                justify-content: center;
                margin-bottom: 1rem;

                .otp-input-wrapper {
                    text-align: center;

                    .otp-input {
                        font-size: 1.5rem;
                        padding: 10px;
                        width: 50px;
                        height: 50px;
                        text-align: center;
                        border: 1px solid #ddd;
                        border-radius: 5px;
                    }

                    .otp-input:focus {
                        border-color: #5b99a6;
                        outline: none;
                    }
                }
            }

            .otp-timer {
                text-align: center;
                color: #333;
                margin-bottom: 1rem;
                font-size: 0.875rem;
            }

            .custom-button {
                margin-top: 1rem;
            }
        }
    }
}
.otp-form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .otp-inputs {
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;

        .otp-input-wrapper {
            text-align: center;

            .otp-input {
                font-size: 1.5rem;
                padding: 10px;
                width: 50px;
                height: 50px;
                text-align: center;
                border: 1px solid #ddd;
                border-radius: 5px;
            }

            .otp-input:focus {
                border-color: #5b99a6;
                outline: none;
            }
        }
    }

    .otp-timer {
        text-align: center;
        color: #333;
        margin-bottom: 1rem;
        font-size: 0.875rem;
    }

    .custom-button {
        margin-top: 1rem;
    }
}