/* style.scss */
.reacttabs {
    width: 100%;
  
    .tablist {
      display: flex;
      width: 100%;
      justify-content: space-between;
  
      .tab {
        width: 50%;
        text-align: center;
        cursor: pointer;
        transition: border-color 0.3s, color 0.3s;
        padding: 5px;

      }
      .tab:focus {
        outline: none;
      }
    }
  
    .tabpanel {
      width: 80%;
      margin: auto;
  
      p {
        font-size: 10px;
        width: 100%;
        float: right;
        color: black;
      }
    }
  }
  