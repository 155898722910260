@import "./../../assets/index.scss";

.reciept{
  
  padding: 37px;
  width: 70vw;
  display: flex;
  flex-direction: column;
  gap: 8px;

  div{
    padding: 9px;
  }
  



}
 .bid-table{
 
  border: 1px solid #ddd;
    padding: 8px;
    text-align: left;



}
.csv-container {
  
    padding: 37px;
    width: 70vw;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .csv-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .csv-table th, .csv-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .csv-button {
    display: flex;
    background-color: black;
    color: white;
    border: none;
    font-weight: bold;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  .button{
    display: flex;
    justify-content: center;
  }
  .data{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    min-height: 50vh;
    align-items: center;

    h2{
      text-align: center;
      padding: 20px;
  
    border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background-color: #E7E5E2;
      margin-top: 3vh;
    }
    th, td{
      text-align: center;
    }
  
  }
  .timeToTherapyStyle{
    margin-top:20px;
    margin-bottom:20px;
    font-weight:'bold';
    font-size:20px;
  }