@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../../assets/index.scss";

.notificationContainer {
  width: 100%;
  height: auto;
  padding: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    margin-bottom: 5vh;
    text-align: center;
  }

  .notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    width: 80%;
    background-color: $bodygray;
    cursor: pointer;
    &:hover {
      background: linear-gradient(
        135deg,
        #5b99a6 0%,
        #5b99a6 50%,
        #7db9e8 100%
      ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    .notificationIcon {
      margin-right: 10px;
      font-size: 20px;
      color: #ff0000;
    }

    .notificationMessage {
      flex: 1;
      font-size: 14px;
      display: flex;
      flex-direction: column;

      .time {
        font-size: 10px;
      }
      .notificationNumber {
        font-weight: bold;
      }
    }

    .notificationDelete {
      margin-left: 10px;
      font-size: 20px;
      color: #000000;
      cursor: pointer;
      transition: color 0.3s ease;

      &:hover {
        color: #ff0000;
      }
    }
  }
  .read {
    background-color: white; // Background color when read is true
  }
}
