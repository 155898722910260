@import "./../../assets//index.scss";
.back_header {
  height: "90px";
  background: #a19fa0;
  .c1 {
    height: 100%;
    padding: 12px;

    button {
      background-color: black;
      font-size: 30px;
    }
  }
}
