@import "../../assets/index.scss";

.main-container {
    height: auto;
    width: auto;

    .coverimage {
        height: 100vh;
        width: 100%;
        object-fit: cover;
        transition: transform 0.3s ease;

        @include breakpoint(tablet) {
            height: 100vh;
            width: 100%;
            
        }

        .coverimage.zoomed {
            transform: scale(1.2);
            /* Adjust the scale factor as needed for zooming */
        }

    }

    .text-overlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #262222;
        font-size: 2.4rem;
        font-weight: bold;
        text-align: center;
        display: flex;
        width:70%;
        flex-direction: column;
        justify-content: space-between;
        //  background-color: rgba(130, 127, 127, 0.7); 
        padding: 10px;
// height: 20%;
p{
    font-size: 1.2rem;
}
        @include breakpoint(belowLsTablet) {
            position:absolute;
            top: 50%;
            left: 50%;

        }
    }

    .explore-button {
        position: absolute;
        // margin-top: 3rem;
        top:59%;
        width:100%;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: large;
        border-radius: 5px;
    }

    .button {
        padding: 12px 55px;
        text-align: center;
        text-decoration: none;
        font-weight: bold;
        display: inline-block;
        font-size: 16px;
        margin: 10px 2px;
        cursor: pointer;
        border-radius: 8px;
        margin-top:2px;
        margin-bottom: -50px;
    }


}

.info {
    display: flex;
    flex-direction: column;
    padding: 70px;
}

.sharedata {
    margin-bottom: 68px;
    margin-left: 15px;
}

.buydata {
    margin-bottom: 68px;
    margin-left: 15px;
}

hr {
    border-bottom: 1px solid black;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
}

.card-img-custom {
    height: 200px;
    /* Adjust the height as needed */
    object-fit: cover;
}