@import "./../../assets/index.scss";

.wallet_sec {
  padding: 50px 0;

  h1 {
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
  }

  h2 {
    color: #000000;
    text-align: left;
    margin-bottom: 20px;
  }

  .container2 {
    margin-top: -50px;
  }

  .card {
    background-color: #ffffff;
    border: none;

    .card-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid rgb(168, 168, 168);

      p {
        img {
          max-width: 40px;

          margin-right: 10px;
        }

        margin: 0;
      }

      .arrow-icon {
        i {
          color: #a3a2a2;
          font-size: 20px;
          float: right;
        }
      }
    }
  }

  span {
    color: #afafaf;
    font-weight: 700;
    font-size: 20px;
  }

  .usb {
    border-bottom: 1px solid #f0f0f1;

    p {
      padding-left: 10px;
    }
  }

  .p-4 {
    img {
      width: 75%;
    }
  }
}

.wallet_sec1 {
  padding: 50px 0;

  h1 {
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
    // font-size: $pageheadingsize;
  }

  .balance-card {
    display: flex;
    background-color: #ebe9e9;
    border: none;

    .balance-card-body {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bal {
        h3 {
          position: relative;
          right: 2px;
        }
      }

      i {
        color: #a3a2a2;
        font-size: 20px;
      }
    }
  }
}

.method-sec {
  padding: 50px 0;

  .addMethod-card {
    margin-bottom: 50px;
    cursor: pointer;
    background-color: #ffffff;
    border: none;

    .addMethod-card-body {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h3 {
      position: relative;
      right: 2px;
    }

    i {
      color: #a3a2a2;
      font-size: 20px;
    }
  }
}

.confirmbutton {
  margin-top: 50px;
  background-color: #5b99a6;
  width: 30rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;

  &:hover {
    background-color: rgb(235, 235, 235);
    color: #000000;
  }
}

.closebutton {
  background-color: #8b0000 !important;
  width: 30rem !important;

  &:hover {
    background-color: rgb(235, 235, 235) !important;
    color: #000000 !important;
  }
}

.addCard-buttons {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.close {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 44px;
  gap: 44px;
}

.closeremovecardbutton {
  // background-color: #5b99a6;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  // transition: background-color 0.3s ease-in-out;

  width: 20vw;
  margin-top: 15px;

  &:hover {
    background-color: #3ed652;
    color: #f4eded;
  }
}

.close1 {
  display: flex;
  flex-direction: row;
  gap: 13px;
  justify-content: center;
}

.react-phone-input {
  --PhoneInputCountryFlag-height: 5px;
}

.PhoneInputCountryIconImg {
  height: 20px;
  margin: 5px;
}

.PhoneInputCountry {
  display: flex;
}

.PhoneInputCountryIcon .PhoneInputCountryIcon--border {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PhoneInputCountrySelect {
  width: 25vw;
}

.adddetails {
  margin-bottom: 60px;
}

.transaction-card {
  border: none;
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f7;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;

  &.credited {
    border-left: 4px solid #5b99a6;
  }

  &.withdrawal {
    border-left: 4px solid red;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
  }

  .transaction-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .transaction-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      margin-right: 10px;

      .svg-placeholder {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #e0e0e0;

        .credited-icon {
          /* Credited SVG placeholder styling */
          color: #5b99a6;
          /* Color for credited transactions */
        }

        .withdrawal-icon {
          /* Withdrawal SVG placeholder styling */
          color: red;
          /* Color for withdrawal transactions */
        }
      }
    }

    .transaction-type {
      font-size: 1.2em;
      font-weight: 600;
      margin: 0;
      color: inherit;
      /* Inherits color based on credited or withdrawal */
    }

    .transaction-date {
      color: #6c757d;
      font-size: 0.9em;
      margin: 0;
    }
  }

  .transaction-details {
    padding-left: 50px;

    p {
      margin: 5px 0;
      font-size: 0.95em;
      color: #333;

      strong {
        color: #444;
      }
    }
  }
}