.heading {
  font-size: 2.5rem;
  color: #333;
  text-align: center;
  // margin-bottom: 2rem;
  margin-top: 1rem;
  // text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.Username {
  margin-top: 5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexbox-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.headings {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 2rem;
  text-decoration: underline;
}

.output-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f3f2f2;
  border-radius: 10px;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // transition: transform 0.3s ease;
}

.output-field-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
  margin-bottom: 1rem;
  padding: 1rem;
  // background-color: #f3f2f2;
  border-radius: 10px;

}

.inner-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
  margin-top: -1rem;
  padding: 1rem;
  // background-color: #dfdfdf;
  border-radius: 5pxrgb(126, 126, 126);

}



.label {
  font-size: 1.3rem;
  color: #000;
  font-weight: bold;
}

.box-label {
  font-size: 1.3rem;
  color: #000;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}

.value {
  font-size: 1.2rem;
  // margin-top: 1rem;
  padding: 0.3rem;
  color: #333;
}

.ApproveBtn .buttonBuy {
  margin-bottom: 38px;
  margin-top: 38px;
  padding: 10px;
  background-color: #5b99a6;
  border: none;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.diagnosis-label {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  text-align: left;
  margin-bottom: 1rem;
}

.diagnosis-item,
.therapy-item {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
  border: 1px solid #ddd;
  border-radius: 1rem;
  padding: 1rem;
  cursor: pointer;
  // padding-bottom: 10px;
}

.diagnosis-name {
  font-size: 1.5rem;
  text-align: center;

  // margin-left: 20vh;
  cursor: pointer;
  font-weight: bold;
}

.diagnosis-dates {
  font-size: 1rem;
  cursor: pointer;
  color: #555;
}

.analysis-section {
  border: 1px solid #ccc;
  border-radius: 1rem;
  padding: 1.5rem;
  margin: 0.5rem;
  margin-top: 1rem;
}

.analysis-dates {
  font-size: 1rem;
  margin-top: 1rem;

}

.analysis-section .date-range {
  padding: 5px;
  margin-bottom: 5px;
  color: #333;
  // border-bottom: 1px solid #ddd;
}



.date-range:last-child {
  border-bottom: none;
}

.therapy-item {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.therapy-name {
  font-size: 1.5rem;
  cursor: pointer;
  font-weight: bold;
}

.therapy-dates {
  font-size: 1rem;
  cursor: pointer;
  color: #555;
}

.dates {
  font-size: 1rem;
  color: #4f4f4f;
}

.diagnosis-item,
.therapy-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.diagnosis-content,
.therapy-content {
  display: flex;
  justify-content: space-between;
}

.diagnosis-name,
.therapy-name {
  font-size: 1.5rem;
  font-weight: bold;
}

.diagnosis-dates,
.therapy-dates {
  font-size: 1rem;
  // color: #555;
}

.delete-icon {
  font-size: 1.5rem;
  position: absolute;
  right: 0;
  margin-right: 1.3rem;
  color: red;
  cursor: pointer;
  margin-left: 10px;
}


.delete-icon {
  font-size: 1.5rem;
  text-align: right;
  color: black;
  cursor: pointer;
  margin-left: 2rem;
}

.skeleton-item {
  width: 50%;
  height: 20rem;
  background-color: #e0e0e0;
  margin-left: 20rem;
  margin-bottom: 10px;
  border-radius: 4px;
  animation: skeleton-loading 1.2s infinite;
}

@keyframes skeleton-loading {
  0% {
    background-color: #e0e0e0;
  }

  50% {
    background-color: #c7c7c7;
  }

  100% {
    background-color: #e0e0e0;
  }
}

.diagnosis-section,
.therapy-section {
  border: 1px solid #ccc;
  padding: 20px;
  margin-right: 1vh;
  margin-left: 1vh;

  border-radius: 1rem;
}

.toggleButton {
  color: #5b99a6;
  text-decoration: underline;
  cursor: pointer;
  padding: 1vb;
  font-weight: bold;
  text-align: right;
}

.confirmBtn {
  background-color: #5b99a6;
  color: white;
  width: 40vw;
  padding: 0.5rem;
  border-radius: 0.2rem;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}

.custom-close-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #5b99a6;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 10px;
}

.general-info-box {
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 12px;
  margin: 20px 0;
}

.general-info-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;

  .general-info-label {
    font-weight: bold;
    font-size: 1.2rem;
    margin-right: 15px;
  }

  .general-value {
    margin-left: 10px;
    color: #333;
  }
}