@import "./../../assets/index.scss";

.datacontainer {
  padding: 50px 0;
  background-color: $bodywhite;
  align-items: center;
  margin: auto;
  width: 80%;
  @include breakpoint(mobile) {
    width: 98%;
  }
  h1 {
    
    color: $bodytext;
    text-align: center;
    margin-bottom: 30px;
    font-size: $pageheadingsize;
  }
  .categories_list {
    .col1 {
      display: flex;
      flex-direction: column;
      margin: 15px;

      padding: 30px;
      background-color: $bodygray;
      border-radius: 50%;
      width: 175px;
      height: 175px;
      align-items: center;
      text-align: center;
      justify-content: space-between;
      margin-top: 15px;
      @include breakpoint(mobile) {
        width: 170px;
        height: 170px;
        padding: 25px;
        margin: 8px;
      }
    }
  }
  .row {
    width: 100%;
    justify-content: center;

    img {
      margin-bottom: 5px;
      width: 90px;
      height: 90px;

      border-radius: 50%;
    }

    span {
      text-decoration: none;
      justify-content: center;
      align-items: center;
      color: bodywhite;
      font-size: 12px;
      margin: 10px; /* Add some margin between elements on small screens */
    }
  }
}
