.container65 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 50px;
  flex-direction: column;
  h1 {
    text-align: center;
  }
  .fields {
    width: 50%;

    .priceform {
      width: 70%;
      margin: auto;
      margin-top: 20px;
    }
  }
}
