.buyerInfoForm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.buyerForm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}
.buyerinfo {
  padding: 0px;
  width: 100%;
}

.userName {
  font-size: 42px;
  font-weight: 500;
  margin-bottom: 20px;
  color: #333;
}

.formFields {
  margin-bottom: 20px;
}

.formFields label {
  font-size: 14px;
  font-weight: 500;
  color: #666;
}

.formFields .form-control {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 12px;
  font-size: 16px;
  color: #333;
}

.submitBuyerInfo {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.submitBuyerInfo .buyerSubmit {
  width: 50vw;
  margin-top: 20px;
  background-color: #5b99a6;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 4px;
}
