.containerofassignuser{
    padding: 80px ;
}
.div1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // width: 600px;
    // max-width: 100%;
    padding: 38px;
    margin: 0 auto;
 
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(162, 152, 152, 0.2);
}
.Addauser{
    margin-top: none;
    width: 13vw;
    margin-right: 2vw;
}
.SearchItems{
   
    width: 40vw;
}

.addedusers{

    // width: 600px;
    // max-width: 100%;
    padding: 38px;
    margin: 0 auto;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-top: 18px;
}
.icon-buttons{
    background-color: black;
    border-radius: 6px;
    height:44px;
    margin-top: auto;
}