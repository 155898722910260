@import "../../assets/index.scss";
.survey-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    margin: 100px;
    h2{
        padding: 26px;
    }
    form{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
  }
  
  .question-container {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 16px;
    margin-bottom: 16px;
    width: 80vw;
    max-width: 650px;
    background-color: $bodygray;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 8px;
  }
  
  label {
    display: flex;
    align-items: center;
  }
  
  input {
    margin-right: 8px;
  }
  /* Survey form container */
.survey-form {
  margin: 0 auto;
  width: 80%;
  padding: 20px;
  border: 1px solid $bodygray;
  border-radius: 5px;
  background-color: rgba(220, 217, 217, 0.477);
  box-shadow: 0 0 10px rgba(91, 153, 166,0.4);
  .titleQuestionnaire{
    height: 7vh;
    color: 'rgb(137 134 129)';  
    font-size: '30px';
    width:50px;
    text-align: center;
  }
}

/* List group */
.list-group {
  margin-bottom: 0;
}

/* List group item */
.Container .list-group-item {
  border: none;
  margin-top: 15px;
  // border-bottom: 1px solid #ccc;
  border-radius:10px;
  // padding: 10px 0;
  // background-color: $bodygray
}

/* Input group */
.input-group {
  margin-bottom: 10px;
}

/* Form control */
.form-control {
  border: 1px solid #ccc;
  border-radius: 50px;
  padding: 5px 10px;
 outline: none;
 color: black;

}
.form-control:focus {
  
 outline: none !important;

}
.form-control::placeholder { 
  color: $LoginContainerColor !important;
  
 
}



/* Buttons */
.btn {
  border-radius: 5px;
}

/* Add question button */
.btn-primary {
  background-color: black;
  color: white;
}

/* Remove question button */
.btn-danger {
  background-color: rgb(163, 54, 54);
  color: white;
}

/* Complete survey button */

.complete{
  display: flex;
 
  width: 100vw;

  align-items: center;
  justify-content: center;
  margin: 8px;
  padding: 61px;

 
}
.Container{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 100px;
}
.completeButton .btn .btn-primary{
  background-color: $primaryheaderbg !important; 
  // width: 45%;
  // // backgroundColor: '#5b99a6', 
  // border: none;
  // height:47px;
}
.disabledinput-container {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  color: #555;
  cursor: not-allowed; /* Set cursor to not-allowed when disabled */
}

.question-input {
  border: none;
  border-bottom: 2px solid grey;
  transition: border-bottom 0.3s ease;
}

.question-input:focus {
  outline: none !important;
  border-bottom: 2px solid $primaryheaderbg;
}
.del-icons{
  margin-right:2%;
}