.privacypolicy {
    padding: 79px;
  
    hr {
      border-bottom: 1px solid black;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  
  .paras {
    text-align: justify;
  
    ul {
      list-style-type: disc;
      margin-left: 20px;
    }
  
    li {
      margin-bottom: 5px;
    }
  }
  