.custom-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Center the form vertically */
    background-color: #f9f9f9;
}

.custom-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
}

.custom-form-heading {
    font-family: "Roboto", sans-serif;
    font-size: 24px;
    margin-bottom: 2hv;
    text-align: center;
    color: #333333;
}

.custom-input {
    width: 100%;
    padding: 15px;
    margin-bottom: 20px;
    font-size: 16px;
    border: 1px solid #d1d1d1;
    border-radius: 8px;
    background-color: #f5f5f5;
    outline: none;
    box-sizing: border-box;
    transition: all 0.3s ease;

    &:focus {
        border-color: #8b0000;
        background-color: #ffffff;
    }

    ::placeholder {
        color: #90a4ae;
    }
}

.custom-terms-section {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.custom-checkbox {
    margin-right: 10px;
}

.custom-terms-label {
    font-size: 14px;
    color: #333333;

    a {
        color: #8b0000;
        text-decoration: none;
        font-weight: bold;
    }
}

.custom-delete-button {
    font-family: "Roboto", sans-serif;
    display: inline-block;
    width: 100%;
    padding: 15px;
    background-color: #8b0000;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    text-transform: uppercase;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    text-align: center;

    &:hover {
        background-color: #a60000;
    }

    &:disabled {
        background-color: #d3d3d3;
        cursor: not-allowed;
    }
}

.para-details {
    padding: 20px;
    display: flex;
    margin-top: 5%;
    font-size: larger;
    justify-content: center;
    align-content: center;
}