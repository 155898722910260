@import "./../../assets/index.scss";
.my_bids_sec {
  padding: 50px 0;
  h1 {
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
    font-size: $pageheadingsize;
  }

  
  // .card {
  //   border: none;
  //   background-color: $bodygray;
  //   .card-body {
  //     .my_bids_buttons {
  //       display: flex;
  //       justify-content: space-between;
  //       flex-direction: column;
  //       .btn-default-1 {
  //         text-decoration: none;
  //         color: white;
  //         font-size: 10px;
  //         font-weight: normal;
  //         background-color: #5c9ba7;
  //         padding: 8px 18px;
  //         border-radius: 5px;
  //         display: flex;
  //         flex-direction: column;
  //         white-space: nowrap;
  //         align-items: center;
  //         margin-bottom: 10px;
  //         &:last-child {
  //           margin-bottom: 0;
  //         }
  //       }
  //     }
  //   }
  // }
  // .btn-default-1 {
  //   text-decoration: none;
  //   color: white;
  //   font-size: 14px;
  //   font-weight: bold;
  //   background-color: #5c9ba7;
  //   padding: 8px 18px;
  //   border-radius: 5px;
  //   display: flex;
  //   flex-direction: column;
  //   text-align: center;
  //   margin-bottom: 10px;
  // }
}

/* style.scss */
.contianer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // align-items: center;
  height: auto;

  h2 {
    text-align: center;
    font-size: $pageheadingsize;
  }
  h3 {
    text-align: center;
  }
  .div1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    width: 80%;
    margin-top: 50px;

    .div2 {
      width: 56%;
      text-align: left;

      margin: auto;
      justify-content: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include breakpoint(mobile) {
        width: 100%;
      }
      
      .username {
        margin-bottom: 10px;
        
      }      
      .user-available {
        // border: 2px solid green;
       
      }
      .user-unavailable{
        border: 2px solid rgb(252, 101, 101);
      
        
      }
      .form-group {
        width: 100%;
        outline: none !important;
  box-shadow: none !important;
      }
      
    }

    .button-container {
      background-color: #5c9ba7;
      border: 2px solid #5c9ba7;
      margin: auto;
      width: 25%;
      margin-top: 20px;
      @include breakpoint(mobile) {
        width: 100%;
      }
    }
  }
  .datameasure {
    width: 45%;
    text-align: left;

    margin: auto;
    justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include breakpoint(mobile) {
      margin-top: 10px;
      width: 80%;
    }
  }
  .addedusers {
    .row {
      width: 45%;
      text-align: left;

      margin: auto;
      justify-content: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @include breakpoint(mobile) {
        width: 90%;
      }
    }
  }
}

/*list items search*/
/* style.scss */

/* Style for the ListGroup container */
.list-group-container {
  margin-top: 20px;
}

/* Style for ListGroup items */

.listgroup{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.bid-list-item {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
  background-color: $marketbids-card;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  display: flex;
justify-content: space-between;
  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }

  p.bidtitle {
    font-size: 23px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  button {
    margin-top: 8px;
    
  }
}
.detailsbutton{
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

width: 13vw;

margin-top: 25px;


&:hover {
  background-color: #3cab05;
  color: #f4eded;
}
}
.configurebutton{
  background-color: $primaryheaderbg;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  min-width: 110px;
  height: 35px;
// margin-top: 20px;


&:hover {
  background-color: $primaryheaderbg;
  opacity: 0.9;
  color: #f4eded;
}
}
.configurebutton .dropdown-toggle .btn .btn-primary{
  background-color: #000;
}
.button{
  gap: 14px;
  display: flex;
  flex-direction: column;
}
.selected-survey {
  font-weight: bold;
}

.bid-settings{
  display: flex;
  margin-top: 5px;
  flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Settings{
  display: flex;
  gap: 20px;
}
.bid-icons{ 
  cursor: pointer;
  margin:5px;
}
.closebutton{
   
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

width: 100%;
margin-top: 15px;


&:hover {
  background-color: #d63e3e;
  color: #f4eded;
}
}

.questionnaire{
  width: 100%;
  border-bottom: 2px solid #c1c1c1
}


.allTherapies {
  display: flex;
  flex-direction: row;
  gap: 2px;
  overflow-x: auto;
  max-width: 72vw;
  flex-wrap: nowrap; // Ensures items stay in a single line
  scrollbar-width: thin; // For Firefox
  scrollbar-color: #888 #fff; // For Firefox
  padding-bottom: 10px;
  .singleTherapy {
    background-color: white; 
    border: 1px solid #c1c1c1;
    margin-right: 5px;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
    white-space: nowrap; // Prevents line breaks within items
    cursor: pointer; // Add a pointer cursor to indicate it's clickable
    transition: background-color 0.3s ease; // Smooth transition for background color change
  
  }
  .SelectedsingleTherapy {
    background-color: $greenappcolor; 
    color: $white;
    margin-right: 5px;
    width: fit-content;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 20px;
    white-space: nowrap; // Prevents line breaks within items
    cursor: pointer; // Add a pointer cursor to indicate it's clickable
    transition: background-color 0.3s ease; // Smooth transition for background color change
  
  }
}
.sub-heading{
  padding-top: 10px;
  font-weight: 600;
}
.bold-text-green{
  color:$greenappcolor;
  font-weight: 600;
}

