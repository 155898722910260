.container {
    padding: 40px;
    max-width: 1200px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
}

.header {
    text-align: center;
    color: #333;
    margin-bottom: 30px;
}

.dropdownContainer {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}

.select {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
}

.graphPlaceholder {
    width: 100%;
    height: 400px;
    background-color: #f0f0f0;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    color: #666;
    margin-bottom: 30px;
}

.infoBoxContainer {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 30px;
}

.infoBox {
    flex: 1;
    background-color: #5b99a637;
    border: 1px solid #5b99a6;
    border-radius: 5px;
    padding: 15px;
    font-size: 16px;
    color: #5b99a6;
}
.infoBox2 {
    flex: 1;
    background-color: #5b99a637;
    border: 1px solid #5b99a6;
    border-radius: 5px;
    padding: 15px;
    font-size: 16px;
    color: #5b99a6;
    max-width: 50%;
    margin-left: auto;
}
.updateButton {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #5b99a6;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* Add these styles to your .scss file */
.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    /* Same as chart height */
}

.loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}