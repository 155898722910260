/* CustomCard.module.scss */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./../../assets/index.scss";

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  background-color: $marketbids-card;
  border: 1px solid $marketbids-card;
  padding: 15px;
  border-radius: 10px;

  .card-text {
    display: flex;
    justify-content: space-between;
    font-size: medium;
    margin: 0;
    padding: 0;
  }
}

.bidtitle {
  font-size: larger;
  font-weight: bold;
  text-align: center;
}
.assignusers {
  display: flex;
  text-align: center;
  flex-direction: column;
  cursor: pointer;

  ul {
    cursor: pointer;
    display: flex;
    // border: 2px solid #3498db;
    flex-direction: column;
    padding: 9px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    margin-top: 19px;
    justify-content: center;

    li {
      font-weight: bold;
    }

    span {
      margin: auto;
      font-weight: 600;
    }
  }
}
.closearrow {
  position: relative;
  left: 33vw;
  bottom: 4vh;
  font-size: 22px;
  cursor: pointer;
}
.btn {
  padding: inherit;
}
.description-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  p {
    padding: 10px; // Padding around the text
    border-radius: 8px; // Rounded corners
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); // Optional: Add some shadow
  }
}
