.graphButtons{
    justify-content: center;
    align-items: center;
    display:flex ;
    flex-direction: column;
    gap:8px;
}
// .graph-container{
//     width:70vw;
// }
.graph-container {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
  }
  .graph-container::-webkit-scrollbar {
    display: none; /* Chrome, Safari, and Edge */
  }