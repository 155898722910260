@import "../../../assets/index.scss";

*:focus {
  outline: none;
}

.generalinfo {
  width: 100vw;
  height: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin: 0 auto;

  p {
    margin-bottom: 0;
    font-size: larger;
  }
}

.form-select {
  background-color: #f4f4f4;
  color: rgb(0, 0, 0);
}

.form-control {
  background-color: #f4f4f4;
  color: rgb(0, 0, 0);
}

.css-qbdosj-Input {
  background-color: #f4f4f4;
  color: rgb(0, 0, 0);
}

.form1 {
  // width: 40%;
  max-width: 60vw;
  height: 50%;
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  justify-content: center;

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .buttonBuy {
      background-color: #5b99a6;
      color: white;
      border: none;

      &:hover {
        background-color: $primaryheaderbg;
        color: white;
        /* Original primary color */
        /* Adjust text color or other styles for hover state */
      }
    }
  }
}

.form-dropdown {
  background-color: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  min-width: 60vw;
  justify-content: space-between;
}

.socio {
  width: 70vw;
  height: 5vh;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.outcomes {
  width: 70vw;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.my-button .buttonBuy {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  font-size: 16px;
  gap: 5px;
  border: none;
  border-radius: 5px;
  background-color: #5b99a6;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.my-button:hover {
  background-color: #f4f4f4;
  color: black;
}

.data-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.data-row {
  display: flex;
  justify-content: space-between;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.label {
  font-weight: bold;
}

.socio-embedded {
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.clinicaloutcomes {
  display: flex;

  flex-direction: column;
  gap: 29px;
}

.arrow-button {
  background-color: none;
  color: #5b99a6;
  height: 3rem;
  width: 3rem;
}

.field-dropdown {
  display: inline-flex;
}

button.toggle-arrow {
  font-size: x-large;
  background-color: white;
  color: #5b99a6;
}

.toggle-arrow:hover {
  background-color: #ffffff;
}

.arrow-button {
  background-color: none;
  color: #5b99a6;
  height: 3rem;
  width: 3rem;
}

.field-dropdown {
  display: inline-flex;
}

button.toggle-arrow {
  font-size: x-large;
  background-color: white;
  color: #5b99a6;
}

.toggle-arrow:hover {
  background-color: #ffffff;
}

.css-b62m3t-container {
  display: grid;
}

.userName {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.validationModal {
  background-color: #ffffff;
  color: #1a1a1a;
  padding: 2rem;
  border-radius: 10px;
  width: 40%;
}

.validationModal h2 {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

.validationModal p {
  font-size: 1rem;
  text-align: center;
  margin-bottom: 2rem;
}

.submit-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 10px 20px;
  font-size: 16px;
  gap: 5px;
  border: none;
  border-radius: 7px;
  background-color: #5b99a6;
  color: #fff;
}

.email-validation,
.identifier-validation {
  margin-bottom: 1.5rem;
  text-decoration: none;
}

.email-validation label,
.identifier-validation label {
  display: block;
  font-weight: bold;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
  color: black;
}

.email-validation input,
.identifier-validation input {
  width: 100%;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  border-radius: 6px;
  background-color: #ebe9e9;
  border: transparent;
  color: #000000;
  border-color: none;
  border-color: none;
}

.validationModal {
  position: relative;
}

.close-button {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 30px;
  height: 30px;
  padding: 5px;
  font-weight: bold;
  background-color: rgb(255, 255, 255);
  /* Adjust background color as needed */
  color: #000000;
  /* Adjust color as needed */
  font-size: 13px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-button:hover {
  background: #e0e0e0;
  /* Adjust hover background color as needed */
}

.badge.custom-badge {
  background-color: $primaryheaderbg !important;
  margin-left: 4px;
}

.therapyButtons {
  width: 100%;
  display: flex;
  gap: 10px;
}

.buyerinfo {
  padding: 127px;
}

.diagnosis-card {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  padding: 20px;
  margin: 12px 0;
  /* Light background for contrast */
  /* Subtle shadow for depth */
}


.diagnosis-title {
  font-size: 1em;
  font-weight: 600;
  color: #333;
  margin: 0 0 12px;
  /* Margin below title */
}

.diagnosis-details {
  font-size: 0.75em;
  color: #555;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.detail-label {
  font-weight: 500;
  color: #333;
}