@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../../assets/_variables";
@import "../../assets/index.scss";

@font-face {
  font-family: "AutoWide";
  src: url("../../fonts/Audiowide-Regular.ttf") format("truetype");
}

.login_body {
  background-color: rgb(231, 229, 226);
  min-height: 100vh;
  display: flex;

  flex-direction: column;
  .logo {
    display: flex;
    justify-content: center;
    margin-top: 12vh;
    margin-bottom: 4vh;
    align-items: center;
    .logoimage {
      width: 15vw;
      height: 4vh;

      @include breakpoint(mobileLarge) {
        width: 37vw;
      }
    }
    .backarrow {
      color: #5b99a6;
      cursor: pointer;
      position: absolute; /* Changed to absolute positioning */
      left: calc(
        50% - 17vw
      ); /* Adjust the value to position the arrow to your liking */
      @include breakpoint(mobileLarge) {
        left: 18vw;
      }
    }
  }
  //   h1 {
  //     padding: 15px;
  //     font-family: "Audiowide-Regular";
  //   }

  .containerImage {
    width: 22%;
    margin: 8px auto;
    display: block;
  }
  .container1 {
    // padding-top: 50px;
    background-color: white !important;
    display: flex;
    width: 35%;
    height: auto;
    flex-direction: column;
    align-items: center;
    position: relative;
    margin: auto;
    box-shadow: 1px 10px 8px 0 rgba(0, 0, 0, 0.2);
    padding-bottom: 10px;
    border-radius: 10px;

    .login-link {
      height: 3vh;
      float: right;
      font-weight: bold;

      cursor: pointer;
      font-size: 12px;
      p {
        margin-top: 2px;
      }
    }
  }
  p {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}
.privacyStatement {
  padding: 12px;
}

.formlabel1 form-label {
  font-size: 5rem !important;
}
.privacypolicyy {
  padding: 12px;
}
.loader {
  display: flex;
  justify-content: center;
  align-items: center;
}
.lowerimage {
  width: 5vw;
  height: 8vh;
  @include breakpoint(mobileLarge) {
    width: 17vw;
  }
}
