@import "./../../assets/index.scss";
.back_header {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flow-root;
}
.datalist {
  padding: 50px 0;
  margin-bottom: 20px;
  h1 {
    color: #000000;
    text-align: center;
    margin-bottom: 30px;
    font-size: $pageheadingsize;
  }
  .container {
    // display: flex;
    position: relative;
    bottom: 24px;
    .listitems {
      li {
        // background-color: #e6e5e5;
        height: 35px;
        text-align: center;
      }
    }
  }
}
