@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../../assets/mixins";
@import "../../assets/variables";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: 0.5s all;
}
.category_sec {
  // background-color: $marketbids-card;
  justify-content: center;
  align-items: center;
  .categories_list {
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: large;
   

    @include breakpoint(largeDesktop) {
    }
    @include breakpoint(desktop) {
    }
    @include breakpoint(tablet) {
    }
    @include breakpoint(mobile) {
      flex-direction: column;
    }

    .col1 {
      border-bottom: 2px solid #d7d3d3;
      color: rgb(77, 77, 77);
      padding: 9px;
      justify-content: center;
      align-items: center;
      text-align: center;
      text-decoration: none;
     
      // border-right: 1px solid rgb(109, 109, 109);
      // border-left: 1px solid rgb(109, 109, 109);
      @include breakpoint(mobile) {
        padding: 0;
        width: 100%;
      }
      @include breakpoint(tablet) {
        padding: 5px;
      }
      span {
        text-align: center;
        width: auto;
        padding: 1px;
        white-space: nowrap;
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 14px;
        @include breakpoint(tablet) {
          font-size: 12px;
        }
      }
    }
  }
  .col1.active {
    border-bottom: 2px solid $primaryheaderbg;
    background-color: $marketbids-card;
   color: black;
    cursor: pointer;
  }
  .col1:hover {
    border-bottom: 2px solid $primaryheaderbg;
     background-color: $marketbids-card;
    color: black;
    cursor: pointer;
  }
}
