/* style.scss */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .modal-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 1.5rem;
        color: #333;
        cursor: pointer;
    }

    .modal-input {
        width: 100%;
        padding: 10px;
        font-size: 16px;
        margin-bottom: 1rem;
        border-radius: 5px;
        border: 1px solid #ccc;
        background-color: white;
        cursor: pointer;
    }

    .modal-content {
        background-color: #fff;
        width: 100%;
        max-width: 500px;
        padding: 4rem;
        border-radius: 8px;
        text-align: center;
        animation: fadeInDown 0.3s ease-in-out;

        h2 {
            margin-bottom: 1rem;
            font-size: 1.75rem;
            color: #333;
        }

        p {
            margin-bottom: 1.5rem;
            font-size: 1rem;
            color: #666;
        }

        .modal-button {
            background-color: #5b99a6; // Primary button color
            color: #fff;
            border: none;
            padding: 0.75rem 1.5rem;
            border-radius: 4px;
            font-size: 1rem;
            cursor: pointer;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: darken(#5b99a6, 10%);
            }
        }
    }
}

/* Fade-in animation */
@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}