@import '../../assets/index.scss';
// // body {
// //     font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
// //     background-color: #f0f0f0;
// //     margin: 0;
// //     padding: 0;
// //     display: flex;
// //     align-items: center;
// //     justify-content: center;
// //     height: 100vh;
// //   }
  
// //   .container {
// //     background-color: #fff;
// //     padding: 40px;
// //     border-radius: 8px;
// //     box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
// //     max-width: 400px;
// //     width: 100%;
// //     text-align: center;
// //     backdrop-filter: blur(10px);
  
// //     img{
// //       width: 7vw;
// //       height: 8vh;
// //     }
// //   }
  
// //   h2 {
// //     color: #5b99a6;
// //   }
  
// //   p {
// //     color: #555;
// //     margin-bottom: 20px;
// //   }
  
// //   .verification-link {
// //     display: inline-block;
// //     margin: 20px 0;
// //     padding: 15px 30px;
// //     background-color: #09171a;
// //     color: #fff;
// //     text-decoration: none;
// //     border-radius: 5px;
// //     transition: background-color 0.3s ease;
// //   }
  
  
// //   .verification-link.unsubscribe {
// //     border-radius: 34px;
  
// //   }
// //   .verification-link.unsubscribe:hover {
// //     background-color: #f04242; 
// //   }
  
// //   .verification-link:hover {
// //     background-color: #2980b9;
// //   }
  
// //   .footer {
// //     margin-top: 20px;
// //     color: #888;
// //   }




  
 .back{
    display: flex;
    justify-content: center;
    padding: 139px;
 }

  
  
  .containeremail {
    text-align: center;
    background-color: #fff;
    padding: 68px;
    border-radius: 8px;
    box-shadow: 0 0 69px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 52vw;
    height: 52vh;

    @include breakpoint(mobileLarge){
  
      width: 100vw;
      height: 52vh;
  
    }

    h2{
        color: #5b99a6
    }

    p{
        margin: 10px
    }
    img{
    
      width: 11vw;
      height: 20vh;
      margin-bottom: 5vh;

      @include breakpoint(mobileLarge){
  
       display: none;
    
      }
    }
  }
  
  button {
    background-color: #5b99a6;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  button:hover {
    background-color: #000000;
  }
  