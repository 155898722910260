@import "../../assets/index.scss";
.contain{
    height: auto;
    width: auto;
    display: flex;
    justify-content: center;
    .first{
        height: auto;
        width: auto;
      
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        @include breakpoint(tablet){
           
            flex-direction: column;
        }

        .pocket{

            height: 90%;
            width: 40%;
            
            display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 24px;
    @include breakpoint(mobile){
       
        width: 77vw;
      
    }

        }
        .pocketimage{

            height: auto;
           width: 22vw;
    
            display: flex;
    justify-content: center;
 

            .mobileimage{
                height: 70vh;
                width: 30vw;
                
    @include breakpoint(tablet){
        height: 70vh;
        width: 42vw;
      
    }
    @include breakpoint(belowLsTablet){
        height: 30vh;
        width: 25vw;
      
    }
    @include breakpoint(mobile){
        height: 53vh;
        width: 64vw;
       
      
    }
    @include breakpoint(mobileLarge){
        height: 53vh;
        width: 100vw;
       
      
    }
            }
            
        }
    }
}