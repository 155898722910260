@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./../../assets/index.scss";

.bids_sec {
  padding: 20px 0 50px;
  background-color: $bodywhite;
  position: relative;
  display: flex;
  flex-direction: row;

  @include breakpoint(largeDesktop) {
    // margin-left: 14vw;
  }

  @include breakpoint(desktop) {
    // margin-left: 14vw;
  }

  @include breakpoint(tablet) {
    // margin-left: 14vw;
  }

  .side-nav {
    // background-color: #fafafa;
    width: 20%;
    height: 100%;
    margin-top: 31vh;
    display: flex;
    position: relative;
  }

  h1 {
    color: #0c0c0c;
    text-align: center;
    margin-bottom: 40px;
    font-size: $pageheadingsize;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    /* Adjust alignment */
  }

  .mb-3 {
    margin-top: 0px;
    margin-left: 6px;
    margin-right: 6px;
    width: 100%;
    /* Occupy full width on mobile */
    margin-bottom: 40px;

    /* Add some margin between rows on mobile */
    span {
      font-weight: bold;
      padding: 7%;
    }
  }

  .card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: left;
    background-color: $marketbids-card;
    border: 1px solid $marketbids-card;

    padding: 15px;
    border-radius: 10px;

    // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    .card-text {
      display: flex;
      justify-content: space-between;
      font-size: medium;
      margin: 0;
      padding: 0;
    }
  }

  @media (min-width: 576px) {

    /* For screen sizes equal to or larger than 576px (sm breakpoint) */
    .mb-3 {
      margin-top: 20px;

      width: calc(50% - 15px);
      /* Show two cards side by side with some spacing */
      margin-bottom: 30px;
      /* Add spacing between rows */
    }
  }

  @media (min-width: 768px) {

    /* For screen sizes equal to or larger than 768px (md breakpoint) */
    .mb-3 {
      width: calc(33.33% - 20px);
      /* Show three cards side by side with some spacing */
      margin-bottom: 30px;
      /* Add spacing between rows */
    }

    .card {
      padding: 5px;
    }
  }

  .bid-button {
    display: flex;
    justify-content: center;
    // margin-bottom: 5px;
    margin-right: 180px;

    @include breakpoint(mobileLarge) {
      margin-right: 0;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    // position: absolute;
    padding: 0.5rem 2.1rem;
    font-size: large;
    border: 2px solid gray;
    background-color: white;
    color: rgb(106, 106, 106);
    border-radius: 0.25rem;
    padding-top: 10px;
    flex-direction: row;
    text-decoration: none;
    transition: background-color 0.5s, color 0.5s;
    text-wrap: none;

    i {
      font-size: larger;
      margin-top: -4px;
    }
  }

  // .bid-button{
  //   display: flex;
  //   justify-content: flex-start;
  //   position: absolute;
  // }

  /* Hover styles for the button */
  .button:hover {
    background-color: white;
    border: 2px solid #5db6c7;
    color: #5db6c7;
    // color: rgb(106, 106, 106);
  }
}

fi .bidtitle {
  font-size: larger;
  font-weight: bold;
  text-align: center;
}

.otherFilters {
  width: 100%;

  padding: 10px;
  display: flex;
  justify-content: center;
}

.data-requests h1 {
  margin-right: 30vh;
  margin-top: 4vh;

  @include breakpoint(mobileLarge) {
    margin-right: 0;
  }
}

data-requests-User {
  margin-right: 30vh;
  margin-top: 4vh;

  @include breakpoint(mobileLarge) {
    margin-right: 0;
  }
}

// .css-1fdsijx-ValueContainer{
//   border: 1px solid #070000;
//   padding: 5px 10px;
//   margin-right: 10px;
//   margin-bottom: 10px;
//   display: inline-block;
//   cursor: pointer;
//   height: 5vh;
// }

.css-q2a9mx-control {
  border: 1px solid #070000 !important;
  // padding: 5px 10px;
  // margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
  cursor: pointer;
  min-width: none;
  height: 5vh;
  min-height: 0% !important;
  border-radius: 0% !important;
  -webkit-border-radius: 0% !important;
  -moz-border-radius: 0% !important;
  -ms-border-radius: 0% !important;
  -o-border-radius: 0% !important;
}

.filters {
  display: flex;
  justify-content: center;
  // text-wrap: nowrap;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  // top: 10%;
  width: 100%;
  height: auto;
  // padding-bottom: 20px;
  z-index: 1;

  @include breakpoint(mobile) {
    flex-wrap: wrap;
    height: 11vh;
    width: 55%;
    position: relative;
  }
}

.data-filters {
  // border: 1px solid #070000;
  padding: 5px 5px;
  margin-right: 10px;
  margin-bottom: 10px;
  display: inline-block;
  // align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 5vh;
  z-index: 0;

  @include breakpoint(mobile) {
    z-index: none;
  }
}

.css-tj5bde-Svg {
  color: rgb(92, 91, 91);
}

.css-1xc3v61-indicatorContainer {
  border: none !important;
}

// .data-filters:hover {
//   border: 2px solid #070000;
// }

.content {
  position: absolute;
  // top: 100%;
  // left: 0;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  padding: 10px;
  z-index: 2;
  width: 20vw;

  @include breakpoint(tablet) {
    text-wrap: wrap;
    //  width:60vw;
    right: 40px;
  }

  @include breakpoint(belowLsTablet) {
    text-wrap: wrap;
    // width:78vw;
    right: 40px;
  }

  @include breakpoint(mobileLarge) {
    text-wrap: wrap;
    // width:78vw;
    right: 40px;
  }
}

.scrollable-nav {
  height: 70vh;
  /* Adjust the height as needed */
  overflow-y: auto;
  padding-bottom: 10px;
  // overflow: hidden;
}

.box {
  border: 1px solid #333;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  // overflow: hidden;
  // white-space: nowrap;
  text-overflow: ellipsis;
}

.box.active {
  background-color: #007bff;
  color: white;
}

.medical-array {
  margin-bottom: 20px;
}



.content-sharer {
  position: absolute;
  // top: 100%;
  // left: 0;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 10px;
  z-index: 2;
  width: 15vw;

  @include breakpoint(tablet) {
    text-wrap: wrap;
    width: 60vw;
    right: 40px;
  }

  @include breakpoint(belowLsTablet) {
    text-wrap: wrap;
    width: 78vw;
    right: 40px;
  }

  @include breakpoint(mobileLarge) {
    text-wrap: wrap;
    width: 78vw;
    right: 40px;
  }
}

.form-check-label {
  // @include breakpoint(desktop) {
  //   text-wrap: wrap;

  //  }
  @include breakpoint(largeDesktop) {
    text-wrap: wrap;
  }

  @include breakpoint(tablet) {
    text-wrap: wrap;
  }

  @include breakpoint(belowLsTablet) {
    text-wrap: wrap;
  }

  @include breakpoint(mobileLarge) {
    text-wrap: wrap;
  }
}

.checkbox-label {
  display: flex;
  /* Use flexbox */
  justify-content: space-between;
  /* Align items with space between them */
  // align-items: center; /* Align items vertically */
}

.tick-icon {
  cursor: pointer;
  align-items: end;
}

.inside-filter {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
  position: relative;
  justify-content: center;
}

.css-b62m3t-container {
  display: flex;
  flex-wrap: wrap;
}

.css-b62m3t-container::placeholder {
  color: red;
  opacity: 1;
  /* Firefox */
}

.css-1fdsijx-ValueContainer {
  padding: none !important;
}

.css-1xc3v61-indicatorContainer {
  transition: none;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
}

.css-b62m3t-container:hover {
  border: none !important;
}

.selected-filter-container {
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 15vw;
  height: 15vh;
  padding: 20px;
  margin-bottom: 20px;
}

// .css-b62m3t-container {
//   width: 20vw;
// }

.checkbox-container {
  // margin-bottom: 10px;
  padding: 5px;
  width: 100%;
}

.results {
  // margin-right: 200px;
  align-items: center !important;
  // display: flex;
  // justify-content: center;
  left: 50%;
  margin-left: 200px;
}

.checkbox-container-sharer {
  // margin-bottom: 10px;
  padding: 5px;
  width: 100%;
}

#react-select-3-live-region {
  text-decoration: none;
  border: none;
}

.css-q2a9mx-control {
  border: none;
  outline: 0;
  box-sizing: 0 !important;
  border-color: #070000 !important;
}

.checkbox-container:hover {
  background-color: #e7e5e2 !important;
  color: black !important;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.checkbox-container-sharer:hover {
  background-color: #e7e5e2 !important;
  color: black !important;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.btn1 {
  border: 1px solid black !important;
}

.option-item {
  border-bottom: 1px solid #e4e4e4;
  padding-bottom: 5px;
  /* Adjust this value according to your preference */
  padding-top: 3px;
}

.bids-list {
  th {
    margin: 15px;
  }

  td {
    margin: 15px;
  }
}

.rs-sidenav-default {
  background-color: white !important;
}

.side-nav {
  @include breakpoint(mobileLarge) {
    display: none !important;
  }
}

.dropdown-text {
  padding: 0px;
}