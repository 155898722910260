@import "../../assets/index.scss";

.custom-dropdown .dropdown-toggle {

  color: #333;
  border: none;
  width: 100%;
  border-bottom: 1px solid #E0E0E0;
  border-radius: 0px;
  }

  .custom-dropdown .btn-check:checked + .btn,
  .custom-dropdown .btn.active,
  .custom-dropdown .btn.show,
  .custom-dropdown .btn:first-child:active,
  .custom-dropdown :not(.btn-check) + .btn:active {
    background-color: white; /* Set the default background color */
    color: black;
  }

  
  .custom-dropdown .dropdown-toggle:hover {
    background-color: whitesmoke;
    color: black;
  }
  
  .custom-dropdown .dropdown-menu {
    // background-color: #fff;
    border: 1px solid #ccc;
  }
  
  .custom-dropdown .dropdown-item {
    color: #000000;
    text-decoration: none;

  }
  
  .custom-dropdown .dropdown-item:hover {
    background-color: $primaryheaderbg;   
    color: white;
  }  
  .custom-dropdown .dropdown-item.selected {
    background-color: $primaryheaderbg;
  }
  