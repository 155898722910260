@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../../assets/index.scss";
@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@100..900&display=swap');

#header {
  background-color: $bodygray;

  padding: 10px 0;

  .aic {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 5vw;
    align-items: center;

    @include breakpoint(mobileLarge) {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }

  .aic .logo {
    height: 9vh;
    display: flex;
    text-align: center;
    align-items: center;

    @include breakpoint(mobileLarge) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    img {
      width: 180px;
      height: 195px;

      @include breakpoint(mobileLarge) {
        width: 51vw !important;
        height: 23vh !important;
      }

      @include breakpoint(mobile) {
        width: 33vw;
        height: 23vh;
      }
    }

    a {
      text-decoration: none;
      color: black;
      margin-left: -10px;
      text-align: start;
      font-family: "Audiowide";
      // font-size: 28px;
      // font-weight: 1000;
      border: 0;

      &:focus,
      &:active {
        outline: 0;
        box-shadow: none;
      }
    }
  }

  .form-control {
    width: 24vw;

    // border-radius: 19px;
    @include breakpoint(desktop) {
      width: 25vw;
    }

    @include breakpoint(mobileLarge) {
      width: 35vh;
    }
  }

  .text-end1 {
    color: black;
    display: flex;
    align-items: center !important;

    // justify-content: flex-end;

    // @include breakpoint(desktop) {
    //   text-align: center !important;
    //   margin-top: 15px;
    //   color: black;
    //  }

    .me-3 {
      margin-right: 10px; // Adjust this value as needed
    }

    a {
      color: black;
      display: inline-block;
      margin-left: 15px; // Adjust this value as needed
    }

    @include breakpoint(mobile) {
      text-align: center !important;
      margin-top: 15px;
      color: black;
    }

    .d-inline-block {
      @include breakpoint(mobile) {
        display: block;
        color: black;
        margin-bottom: 10px;
      }

      @include breakpoint(tablet) {
        display: block;
        margin-top: 15px;
        margin-bottom: 10px;
        color: black;
      }
    }
  }
}

.my-custom-class:hover {
  background-color: $primaryheaderbg;
}

.btn-secondary {
  --bs-btn-bg: none;
}

.dropdown-toggle .btn .btn-secondary {
  background-color: greenyellow;
}

.custom-dropdown-toggle::after {
  content: none !important;
}

.m-0 {
  color: black;
}

.language-dropdown {
  margin-left: 5px;
}

.sb {

  flex: 0 1;


  margin-bottom: 2px;

  margin-top: 2px;

  @include breakpoint(desktop) {
    //  color: pink;
  }

  @include breakpoint(mobileLarge) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0;
  }
}

.text-end1 .headerbuttons {
  border-radius: 7px;
  color: black;
  text-wrap: nowrap;
  margin-right: 2px;
  border: 1px solid lightgray;
  background-color: rgb(231, 229, 226);
  text-decoration: none;

  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: $primaryheaderbg;
    color: white;
    // box-shadow: 0 0 0 1px $primaryheaderbg;
    // border: none;
  }
}

.SearchByFilter {
  cursor: pointer;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  z-index: 1;
  width: 24vw;
  height: 24vh;
  display: flex;
  padding: 19px;
  justify-content: space-around;
  align-items: center;
  border: none;

  @include breakpoint(mobile) {
    padding: 2px;
    width: 45vw;
    height: 17vh;
    right: 41px;
  }

  @include breakpoint(mobileLarge) {
    width: 65vw;
    height: 18vh;
  }
}

.opt {
  color: black;
}

// .dropdown-container:hover .dropdown-content {
//   display: block;
// }
.column {
  display: flex;
  gap: 16px;
  flex-direction: column;

  @include breakpoint(tablet) {
    gap: 4px;
    font-size: 14px;
  }

  @include breakpoint(belowLsTablet) {
    gap: 4px;
    font-size: 11px;
  }

  @include breakpoint(mobile) {
    gap: 4px;
    font-size: 16px;
  }

  @include breakpoint(mobileLarge) {
    gap: 4px;
    font-size: 15px;
  }
}

.datadropdown {
  text-transform: capitalize;
}

.form-control .form-control {
  width: 25vw;
}

.side-menu {
  margin-right: 30vw;
}

.side-nav.open {
  display: none;
}

/* Dropdown container */
.dropdown1 {
  position: relative;
  display: inline-block;
}

/* Dropdown button */
.dropdown-toggle1 {
  display: flex;
  background-color: transparent;
  border: 1px solid #ced4da;
  color: #495057;
  padding: 0.5rem 0.5rem;
  font-size: 1rem;
  display: flex;
  cursor: pointer;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 80px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;

  &:hover {
    background-color: $bodygray;
    color: black;
    // border: none;
  }
}

.dropdown-item1 {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0.5rem 1rem;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  min-width: 150px;
  min-height: 40px;
  position: relative;
}

.dropdown-item1:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: lightgray;
}

.dropdown-item1:active,
.dropdown-item1:hover {
  color: $primaryheaderbg;

  background-color: transparent;
  /* Maintain transparency */
}

.dropdown-menu1 {
  position: absolute;
  top: calc(100% + 5px);
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;

  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);

  align-items: center;
  z-index: 1000;
  width: 160px;
  padding-bottom: 3px;
  border-radius: 10px;

  @include breakpoint(mobileLarge) {
    left: -22vw;
  }
}

.custom-dropdown {
  position: relative;
  cursor: pointer;
}

.custom-dropdown-toggle {
  background: none;
  border: none;
  box-shadow: none;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}

.custom-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  // border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  width: 17vw;
  padding-bottom: 3px;

  @include breakpoint(mobile) {
    width: 45vw;
    right: none;
    left: 0;
  }
}

.custom-dropdown-menu>div.dropdown-option {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0.5rem 1rem;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  min-width: 150px;
  min-height: 40px;
  position: relative;
}

.custom-dropdown-menu>div.dropdown-option:hover {
  color: $primaryheaderbg;
}

.custom-dropdown-menu>div.username {
  padding: 0.5rem 1rem;

  white-space: wrap;
}

.divider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: lightgray;
}

.signout-item {
  padding: 0.5rem 1rem;
  color: rgb(246, 136, 136);
  display: flex;
  align-items: center;
  font-weight: bold;
  gap: 5px;
  cursor: pointer;
}

/* Dropdown items hover */

/* Arrow for dropdown button */
.arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-top: 5px solid;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
}

/* Open dropdown menu */
.dropdown-menu1 {
  display: block;
}

.dropdown2 {
  position: relative;
  display: inline-block;
}

/* Dropdown button */
.dropdown-toggle2 {
  display: flex;
  background-color: transparent;
  border: 1px solid #d0d0d1;
  color: #3f4144;
  padding: 0.3rem 0.5rem;
  font-size: 1rem;
  cursor: pointer;
  align-items: center;
  border-radius: 7px;
  // height: 35px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;

  &:hover {
    background-color: $bodygray;
    color: black;
    // border: none;
  }
}

.dropdown-menu2 {
  position: absolute;
  top: 100%;
  right: -40px;
  background-color: #fff;
  // border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  z-index: 1000;
  width: 129px;
  padding-bottom: 3px;

  @include breakpoint(mobileLarge) {
    right: -25px;
  }
}

.dropdown-menu2>div.dropdown-item2 {
  display: flex;
  align-items: center;
  gap: 7px;
  padding: 0.5rem 1rem;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: none;
  min-width: 150px;
  min-height: 40px;
  position: relative;
}

.dropdown-menu2>div.dropdown-item2:hover {
  color: $primaryheaderbg;
}

/* Show the burger menu only for screens below a certain width (e.g., mobile screens) */
@media screen and (max-width: 640px) {
  .burger-menu {
    display: block;
  }
}

.handleNext {
  margin-top: 18vh;
  background-color: $primaryheaderbg;
  width: 50%;

  &:hover {
    background-color: lighten($primaryheaderbg, 10%);
    cursor: pointer;
  }
}

// .css-13cymwt-control{
// width: 100%;
// }

.dropdown_race {
  width: 100%;
  border-color: #dee2e6;
}

.btns {
  margin-top: 35px;
  flex-direction: column;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.username {
  display: flex;
  flex-direction: column;
  background-color: #f3f2f2;
}

.inside-cascader {
  position: relative;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  padding: 21px;
  width: fit-content;
  /* or width: max-content; */
  z-index: 1;
  cursor: pointer;
}

@media (max-width: 768px) {
  .my-modal .ReactModal__Content {
    width: 805vw;
  }
}

.therapy-dates-heading {
  font-family: 'League Spartan', sans-serif;
}