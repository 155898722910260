@import "../../assets/mixins";
@import "../../assets/variables";
.container22{
    width: 100vw;
    height: auto;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 4%;
    padding-bottom: 4%;
    background-color: white;
    display: flex;
    justify-content: center;
    
    flex-direction: column;

  
   

    hr{
        border-bottom: 1px solid black;
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    h2{
        text-align: center;
        margin-bottom: 1vh;
    }

    p{
        text-align: left;
        font-size: larger;
    }

}
