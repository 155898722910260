/* CustomButton.css */
.custom-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  // width: 80%;
  margin-top: 25px;
}

/* Add more color classes as needed */
