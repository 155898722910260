@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../../assets/index.scss";

@font-face {
  font-family: "AudioWide";
  src: url("../../fonts/Audiowide-Regular.ttf") format("truetype");
}
.email-sent-card {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  height: 8vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $primaryheaderbg;
  p {
    font-weight: 600;
    color: #f5f5f5;
    font-size: 14px;
  }
}
.login_body {
  background-color: rgb(231, 229, 226);
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 80px;
  h1 {
    display: flex;
    justify-content: center;
    padding: 15px;
    font-family: "AudioWide";
  }
  .containerImage {
    width: 22%;
    margin: 8px auto;
    display: block;
  }
  .container1 {
    background-color: $LoginContainerColor;
    display: flex;
    width: 35%;
    height: auto;
    flex-direction: column;
    align-items: center;
    position: relative;
    // top: 66px;
    margin: auto;
    justify-content: center;
    box-shadow: 1px 10px 8px 0 rgba(0, 0, 0, 0.2);
    padding-bottom: 50px;

    // .btn1{
    //   width: 100%;
    //   background-color: aqua;
    // }

    // .custbut{
    //   margin-top: 20px;
    //   width:100%;
    // }

    @include breakpoint(tablet) {
      width: 50%;
    }
    @include breakpoint(mobile) {
      width: 80%;
    }
    @include breakpoint(mobileSmall) {
      width: 100%;
    }

    .tablist {
      display: flex;
      list-style: none;
      padding: 0;

      .tab {
        width: 50%;
        text-align: center;
        cursor: pointer;
        border-bottom: 3px solid rgb(231, 229, 229);
        transition: border-color 0.3s, color 0.3s;
        padding: 5px;
      }
    }

    .login-link {
      float: right;
      font-weight: bold;
      margin-top: 2px;
      cursor: pointer;
    }
  }
}
.backarrow-signup {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 16vh;
  margin-bottom: 15px;
}
.privacyStatement {
  hr {
    border-bottom: 1px solid black;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
.logoimage {
  width: 15vw;
  height: 4vh;

  @include breakpoint(mobileLarge) {
    width: 37vw;
  }
}
.bckArrow {
  color: #5b99a6;
  cursor: pointer;
  position: absolute; /* Changed to absolute positioning */
  left: calc(
    50% - 17vw
  ); /* Adjust the value to position the arrow to your liking */

  @include breakpoint(mobileLarge) {
    bottom: 8px;
    left: 18vw;
  }
}
.lowerimage {
  width: 5vw;
  height: 8vh;
  @include breakpoint(mobileLarge) {
    width: 17vw;
  }
}
.cls {
  display: flex;
  justify-content: center;
}
// .close-button {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   background: none;
//   border: none;
//   cursor: pointer;
//   font-size: 20px;
//   color: #333; // Adjust color as needed
// }
