@import "../../assets/index.scss";
.footer {
  display: flex;
  justify-content: center;
  height: auto;
  background-color: $bodygray;
  padding-Top: 25px;

  padding-bottom: 5px;
  
  .row {
    text-align: center;
    justify-content: space-between;

    @include breakpoint(tablet) {
      flex-direction: row;
    }
  }
 
}
.team{
  display: flex;
    flex-direction: column;
    gap: 16px;
}
 .row2{
  text-align: center;
  justify-content: space-between;
}
.icons{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @include breakpoint(mobileLarge) {
    flex-direction: column;
    align-items: center;
  }


}
.playstore-icons{
  @include breakpoint(mobileSmall) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
}
.social-icons{
  justify-content: center;
  width: 17vw;
  display: flex;
  align-items: center;
}
