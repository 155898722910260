@import "./../../assets/index.scss";
.header {
  width: 100%;
  padding: 2rem;
  border-bottom: 1px solid #e0e0e0;
  justify-content: center;
  align-items: center;
  text-align: center;
  .header-title {
    font-size: 14rem;
    font-weight: bold;
    color: $primaryBlue;
    @include breakpoint(mobile) {
      font-size: 8rem;
    }
  }
  .sub-header {
    font-size: 1.75rem;
    font-weight: medium;
    padding-top: 2rem;
    color: #4b4b4b;
  }
  .sub-text {
    font-size: 1rem;
    font-weight: medium;
    padding: 2rem;
    color: #4b4b4b;
  }
  .button-primary {
    background: linear-gradient(to right, #5c9ba7, $primaryBlue);
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin-right: 0.5rem;
    cursor: pointer;
    text-decoration: none;
  }
  .button-primary:hover {
    background: linear-gradient(
      to right,
      $primaryBlue,
      #5c9ba7
    ); /* New background on hover */
  }
}

.button-secondary {
  background: linear-gradient(to right, #ff7272, #ff4f4f);
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}
