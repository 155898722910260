@import '../../assets/index.scss';


.formcontrol1{
    background-color:red;
    font-size: 10px;
    justify-content: center;
   
}
.formcontrol1::placeholder {
    font-size: 15px; 
    position: relative;
    bottom: 2px;
  }
.formlabel1{
    font-size: 18px;
    font-weight: bold;
}
.error{
    font-size: small;
    color: red;
}