.team-member {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  img {
    margin-top: 50px;
    width: 350px;
    height: 350px;
  }

  .teamHeading {
    text-align: left;
    margin-right: 250px;
  }
  .information {
    padding-top: 10px;
    padding-bottom: 20px;
    h4 {
      margin: 0;
    }

    p {
      margin: 0;
      margin-top: 10px;
      max-width: 350px;
      // text-align: justify;
    }
  }
}
